import { createContext } from 'react';

/**
 * This is used to embed runtime specific settings. Typical examples
 * include region/stage specific data and api configuration.
 */
export interface SettingsContextProps {
  stage: string;
}

export const defaultSettings: SettingsContextProps = {
  stage: process.env.REACT_APP_STAGE || 'dev'
};

export const SettingsContext = createContext<SettingsContextProps>({} as SettingsContextProps);

/**
 * Read settings.json file. If in development environment
 * try to use env variables and fallback to defaults.
 * @returns settings.json file read into a SettingsContextProps object
 */
export const fetchSettings = async () => {
  if (process.env.NODE_ENV === 'development') return defaultSettings;
  const response = await fetch('/settings.json');
  return (await response.json()) as SettingsContextProps;
};
