import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LandingPage from './components/landing-page';
import LayoutHousing from './components/layout-housing';
import PageNotFound from './components/PageNotFound';
import RuleDetails from './components/rules/RuleDetails';
import ReviewRoutes from './components/reviews/ReviewRoutes';

export const ROUTES = createRoutesFromElements(
  <Route path='/' element={<LayoutHousing />}>
    <Route index element={<LandingPage />} />
    <Route path='/reviews/*' element={<ReviewRoutes />} />
    <Route path='/rules/:ruleId' element={<RuleDetails />} />
    <Route path='/*' element={<PageNotFound />} />
  </Route>
);

export default createBrowserRouter(ROUTES);
