import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  columnDefinitions,
  riskTableSortingComparator,
  RISK_UNIQUE_IDENTIFIER_FIELD,
  RiskTableProps,
  someRiskStatus
} from '../table-definitions/risk-table';
import { RiskColumnDefinitionData } from '../table-definitions/common';
import { useCollection } from '@awsui/collection-hooks';
import {
  Table,
  Header,
  SpaceBetween,
  Button,
  TableProps,
  NonCancelableCustomEvent,
  Pagination,
  TextFilter
} from '@amzn/awsui-components-react';
import { SplitPanelContext } from '../../layout-housing/SplitPanel';
import {
  CLEAR_ALL_BUTTON_TEXT,
  getFilterCounterText,
  getTableCounter,
  onClearTableTextFilterClick,
  paginationLabels,
  TableEmptyState,
  TableNoMatchState
} from '../../../common/common-components';
import { CollectionActions } from '@awsui/collection-hooks/dist/mjs/interfaces';
import { RiskStatus } from '../../../common/RiskStatus';
import { RisksContext } from '../context/RisksContext';

const PAGE_SIZE = 20;

const RiskTable = ({
  tableItems,
  isLoadingRisks,
  isMutating,
  isFiltering,
  hasMoreRisks,
  totalRiskCount
}: RiskTableProps) => {
  const { t } = useTranslation();
  const { reviewId } = useParams();
  const splitPanelControls = useContext(SplitPanelContext);
  const [selectedItems, setSelectedItems] = useState<RiskColumnDefinitionData[]>([]);
  const [filterActions, setFilterActions] = useState<CollectionActions<RiskColumnDefinitionData>>();
  const risksControls = useContext(RisksContext);

  const clearAllReviewFilters = (textFilterActions: CollectionActions<unknown> | undefined) => {
    return () => {
      textFilterActions?.setFiltering('');
      risksControls.resetAllRiskFilters();
    };
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, filterProps } = useCollection(
    tableItems,
    {
      pagination: { pageSize: PAGE_SIZE },
      sorting: { defaultState: { sortingColumn: { sortingComparator: riskTableSortingComparator } } },
      selection: { keepSelection: true, trackBy: RISK_UNIQUE_IDENTIFIER_FIELD },
      filtering: {
        empty: totalRiskCount ? (
          <TableNoMatchState
            onClearFilterClick={clearAllReviewFilters(filterActions)}
            buttonText={CLEAR_ALL_BUTTON_TEXT}
          />
        ) : (
          <TableEmptyState resourceName='Risks' />
        ),
        noMatch: <TableNoMatchState onClearFilterClick={onClearTableTextFilterClick(filterActions)} />
      }
    }
  );

  useEffect(() => {
    setSelectedItems([]);
    setFilterActions(actions);
    // equivalent of willUnmount; reset split panel
    return () => {
      splitPanelControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]);

  const onRiskActionButtonClick = (status: string) => {
    return () => {
      risksControls.updateRisks(
        status,
        selectedItems.map((item) => {
          const { resourceId, ruleId, dedupeToken } = item;
          return {
            resourceId,
            ruleId,
            dedupeToken,
            status
          };
        })
      );
    };
  };

  return (
    <Table
      {...collectionProps}
      selectionType='multi'
      onSelectionChange={(
        event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<RiskColumnDefinitionData>>
      ) => {
        setSelectedItems(event.detail?.selectedItems);
      }}
      selectedItems={selectedItems}
      header={
        <>
          <Header
            data-testid='risk-table-header'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='normal'
                  disabled={selectedItems.length === 0 || someRiskStatus(selectedItems, RiskStatus.UNACKNOWLEDGED)}
                  onClick={onRiskActionButtonClick(RiskStatus.UNACKNOWLEDGED)}
                  loading={isMutating}
                >
                  {t('Unapprove')}
                </Button>
                <Button
                  variant='primary'
                  disabled={selectedItems.length === 0 || someRiskStatus(selectedItems, RiskStatus.ACKNOWLEDGED)}
                  onClick={onRiskActionButtonClick(RiskStatus.ACKNOWLEDGED)}
                  loading={isMutating}
                >
                  {t('Approve')}
                </Button>
              </SpaceBetween>
            }
            counter={getTableCounter(selectedItems.length, tableItems.length, hasMoreRisks)}
          >
            {t('Risks')}
          </Header>
        </>
      }
      columnDefinitions={columnDefinitions(String(reviewId), splitPanelControls)}
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder='Search'
          countText={getFilterCounterText(filteredItemsCount || 0)}
        />
      }
      loading={isLoadingRisks || hasMoreRisks || isMutating || isFiltering}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} openEnd={hasMoreRisks} />}
      wrapLines={true}
    />
  );
};

export default RiskTable;
