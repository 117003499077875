import { t } from 'i18next';

export const RISK_TAB_LABEL = t('Risks');
export const RISK_TAB_ID = 'risks-tab';
export const RISK_TAB_ROUTE = 'risks';
export const ALL_CHANGES_TAB_LABEL = t('All Changes');
export const ALL_CHANGES_TAB_ID = 'all-changes-tab';
export const ALL_CHANGES_TAB_ROUTE = 'allChanges';

export const reviewTabsIdToRouteMap: Record<string, string> = {
  [RISK_TAB_ID]: RISK_TAB_ROUTE,
  [ALL_CHANGES_TAB_ID]: ALL_CHANGES_TAB_ROUTE
};
