import React, { useContext, useEffect } from 'react';
import { ColumnLayout, Container, FormField, SpaceBetween } from '@amzn/awsui-components-react';
import {
  allSelectOption,
  findFilterFromParam,
  SearchParams,
  operationSelectOptions,
  extractTypesFromResourceRevisionItems
} from '../table-definitions/filter-menu';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ResourceRevisionsContext } from '../context/ResourceRevisionsContext';
import DynamicSelect from './DynamicSelect';
import { useSearchParams } from 'react-router-dom';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

interface ResourceRevisionFilterMenuProps {
  readonly resourceRevisionsItems: ResourceRevisionList;
  isLoadingResourceRevisions: boolean;
}

const ResourceRevisionFilterMenu = ({
  resourceRevisionsItems,
  isLoadingResourceRevisions
}: ResourceRevisionFilterMenuProps) => {
  const [searchParams] = useSearchParams();
  const resourceRevisionControls = useContext(ResourceRevisionsContext);
  const resourceTypeOptions = [allSelectOption].concat(extractTypesFromResourceRevisionItems(resourceRevisionsItems));
  useEffect(() => {
    if (isLoadingResourceRevisions) return;

    const operationParam = searchParams.get(SearchParams.OPERATION);
    const operationValue = getDefaultOperation(findFilterFromParam(operationSelectOptions, operationParam));
    const resourceTypeParam = searchParams.get(SearchParams.RESOURCE_TYPE);

    const resourceTypeValue = findFilterFromParam(resourceTypeOptions, resourceTypeParam) || allSelectOption;
    resourceRevisionControls.filterResourceRevisions(operationValue, resourceTypeValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingResourceRevisions, resourceRevisionsItems]);

  return (
    <Container>
      <SpaceBetween size='s'>
        <ColumnLayout columns={4}>
          <FormField label='Operation' stretch={true}>
            <DynamicSelect
              isLoading={isLoadingResourceRevisions}
              setFilter={resourceRevisionControls.filterOperation}
              testId='resource-operation-select-filter'
              options={operationSelectOptions}
              value={resourceRevisionControls.operationFilterValue}
              ariaLabel='Selected Resource Revision Operation'
            />
          </FormField>
          <FormField label='Resource type' stretch={true}>
            <DynamicSelect
              isLoading={isLoadingResourceRevisions}
              setFilter={resourceRevisionControls.filterResourceType}
              testId='resource-type-select-filter'
              options={resourceTypeOptions}
              value={resourceRevisionControls.resourceTypeFilterValue}
              ariaLabel='Selected Resource Type'
            />
          </FormField>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
};

/**
 * The operation search param overwrites the default filter if exists.
 * If the search param does not exist the default filter is all.
 *
 * @param operationOption
 * @returns The default value for the operation filter
 */
export function getDefaultOperation(operationOption: OptionDefinition | undefined) {
  if (operationOption !== undefined) return operationOption;
  return allSelectOption;
}

export default ResourceRevisionFilterMenu;
