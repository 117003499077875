import React, { useState, ReactElement, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import {
  SplitPanel,
  AppLayout,
  AppLayoutProps,
  NonCancelableCustomEvent,
  Flashbar,
  FlashbarProps
} from '@amzn/awsui-components-react';
import PageHeader from './PageHeader';
import { SplitPanelContext, SplitPanelI18nStrings } from './SplitPanel';
import { HEADER_ID } from './constants';
import {
  awsErrorToErrorNotificationMessage,
  NotificationsContext,
  notificationsReducer
} from '../../common/Notifications';
import { AWSError } from 'aws-sdk';
import { batchErrorAlertContent } from '../../common/common-components';

const LayoutHousing = () => {
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);
  const [splitPanelContent, setSplitPanelContent] = useState<ReactElement>();
  const [splitPanelContentIdentifier, setSplitPanelContentIdentifier] = useState<string>('');
  const [splitPanelHeader, setSplitPanelHeader] = useState<string>('');
  const [notifications, dispatchNotification] = useReducer(notificationsReducer, []);

  const notificationsContextProviderValue = {
    reset: () => {
      dispatchNotification({ type: 'reset' });
    },
    addNotification: (notification: FlashbarProps.MessageDefinition) => {
      const id = String(notifications.length);
      dispatchNotification({
        type: 'insert',
        notification: {
          ...notification,
          id,
          dismissible: true,
          onDismiss: () => dispatchNotification({ type: 'remove', id })
        }
      });
    },
    addAWSErrorNotification: (error: AWSError, customErrorMessage?: string) => {
      notificationsContextProviderValue.addNotification({
        content: `${customErrorMessage ? customErrorMessage + ' : ' : ''}${awsErrorToErrorNotificationMessage(error)}`,
        type: 'error'
      });
    },
    addSuccessNotification: (notificationContent: string) => {
      notificationsContextProviderValue.addNotification({
        content: notificationContent,
        type: 'success'
      });
    },
    addBatchErrorNotification: (errors: Map<string, string[]>, customErrorMessage: string) => {
      notificationsContextProviderValue.addNotification({
        content: batchErrorAlertContent(errors, customErrorMessage),
        type: 'error'
      });
    }
  };

  const splitPanelContextProviderValue = {
    reset: () => {
      setSplitPanelOpen(false);
      setSplitPanelContent(undefined);
      setSplitPanelContentIdentifier('');
      setSplitPanelHeader('');
    },
    setContent: (content: React.ReactElement | undefined, identifier?: string) => {
      setSplitPanelContent(content);
      setSplitPanelContentIdentifier(identifier || '');
    },
    getContentIdentifier: () => splitPanelContentIdentifier,
    setHeader: (text: string) => {
      setSplitPanelHeader(text);
    },
    setOpen: (isOpen: boolean) => {
      setSplitPanelOpen(isOpen);
    },
    isOpen: () => {
      return splitPanelOpen;
    }
  };

  return (
    <div className='awsui'>
      <PageHeader />
      <SplitPanelContext.Provider value={splitPanelContextProviderValue}>
        <NotificationsContext.Provider value={notificationsContextProviderValue}>
          <AppLayout
            navigationHide={true}
            content={<Outlet />}
            toolsHide={true}
            notifications={<Flashbar items={notifications} />}
            disableContentPaddings={true}
            splitPanel={
              splitPanelContent && (
                <SplitPanel data-testid='split-panel' header={splitPanelHeader} i18nStrings={SplitPanelI18nStrings}>
                  {splitPanelContent}
                </SplitPanel>
              )
            }
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={(event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
              if (event.detail.open) {
                setSplitPanelOpen(event.detail.open);
                return;
              }

              splitPanelContextProviderValue.reset();
            }}
            headerSelector={`#${HEADER_ID}`}
          />
        </NotificationsContext.Provider>
      </SplitPanelContext.Provider>
    </div>
  );
};

export default LayoutHousing;
