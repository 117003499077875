import { ChangeGuardianApprovalService } from '@amzn/change-guardian-approval-service-type-script-client';
import { AWSError } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { InfiniteData } from 'react-query';
import { RiskColumnDefinitionData } from '../table-definitions/common';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

export const toRiskTableItems = (
  infiniteQueryData:
    | InfiniteData<PromiseResult<ChangeGuardianApprovalService.ListRisksByReviewOutput, AWSError>>
    | undefined
): RiskColumnDefinitionData[] => {
  const risks = infiniteQueryData?.pages.flatMap((page) => {
    return page.risks?.map((risk) => {
      return {
        ...risk,
        riskId: [risk.resourceId, risk.ruleId, risk.dedupeToken].join('-'),
        reviewedOn: risk.reviewedOn?.toISOString(),
        createdOn: risk.createdOn?.toISOString(),
        resourceId: risk.resourceId,
        severity: risk.severity,
        dedupeToken: risk.dedupeToken,
        ruleId: risk.ruleId
      } as RiskColumnDefinitionData;
    });
  }) as RiskColumnDefinitionData[];
  return risks ?? [];
};

export const toResourceRevisionsItems = (
  infiniteQueryData:
    | InfiniteData<PromiseResult<ChangeGuardianApprovalService.ListResourceRevisionsByReviewOutput, AWSError>>
    | undefined
): ResourceRevisionList => {
  const resourceRevisions = infiniteQueryData?.pages.flatMap((page) => {
    return page.resourceRevisions;
  }) as ResourceRevisionList;
  return resourceRevisions ?? [];
};
