/**
 * Heavily influenced by https://code.amazon.com/packages/SentrySSOJavascriptHandler/blobs/mainline/--/src/handler.ts
 * Customized for working with our AWS sdk client.
 */

import { ChangeGuardianApprovalService } from '@amzn/change-guardian-approval-service-type-script-client';
import { AWSError, Request } from 'aws-sdk';
import queryString from 'query-string';

interface AuthCache {
  cache: Record<string, number>;
  get: (url: string) => number;
  put: (url: string, expires_at: number) => void;
}

interface SSOResponseData {
  is_authenticated: boolean;
  authn_endpoint?: string;
  expires_at?: number;
}

interface URL {
  baseUrl: string;
  url: string;
  query: queryString.ParsedQuery<string>;
  fragmentIdentifier?: string | undefined;
}

export default class SentrySSOHandler {
  curWindow: Window;
  authCache: AuthCache;

  private static _instance = new SentrySSOHandler();

  // private singleton constructor
  private constructor() {
    this.curWindow = window;
    this.authCache = {
      cache: {},
      get: function (url: string): number {
        return this.cache[url] || 0;
      },
      put: function (url: string, expiresAt: number): void {
        this.cache[url] = expiresAt - 6000;
      }
    };
    SentrySSOHandler._instance = this;
  }

  static get instance() {
    return SentrySSOHandler._instance;
  }

  /**
   * Checks authentication and initates the process if not authenticated.
   * If authentication fails redirects the user to Midway.
   *
   * @param req service request
   */
  async initializeAuth(req: Request<ChangeGuardianApprovalService, AWSError>) {
    const url = SentrySSOHandler.parseURL(req.httpRequest.endpoint.href);
    await this.authenticate(url.baseUrl);
  }

  /**
   * Determines if the user is already auth'd to the destination url
   *
   * @param req service request
   * @returns boolean
   */
  public hasCurrentAuth(req: Request<ChangeGuardianApprovalService, AWSError>): boolean {
    const baseUrl = SentrySSOHandler.parseURL(req.httpRequest.endpoint.href).baseUrl;
    const expiresAt = this.authCache.get(baseUrl);
    if (expiresAt) {
      return expiresAt > Date.now();
    }
    return false;
  }

  /**
   * Helper function to generate a nonce.
   *
   * @returns string
   */
  private static generateNonce(): string {
    let nonce = '';
    const characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 64; i += 1) {
      nonce += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
    }
    return nonce;
  }

  /**
   * Parse and extract components of the url
   * @param url
   * @returns URL object
   */
  private static parseURL(url: string): URL {
    // Regex catches any request type as well as port number
    // eg. https://website.com:3000/?query=value#hash would grab https://website.com:3000
    // 2eg. file://localhost/file/path would grab file://localhost
    const baseREGEX = /^[a-z]+:\/\/[\w.-]+(:[0-9]+){0,1}/;
    const matches = url.match(baseREGEX);
    if (!matches) {
      throw Error(`URL given couldn't be parsed ${url}`);
    }
    return { ...queryString.parseUrl(url, { parseFragmentIdentifier: true }), baseUrl: matches[0] };
  }

  async authenticate(baseUrl: string): Promise<void> {
    // The current window that originated the request
    // (meant for redirect purposes and cleaning up the id_token after a redirect)
    const parsedWindow = SentrySSOHandler.parseURL(this.curWindow.location.href);
    delete parsedWindow.query.id_token;
    this.curWindow.history.replaceState({}, this.curWindow.document.title, queryString.stringifyUrl(parsedWindow));
    // Starts off the login process against Sentry fronted sites
    const ssoUrl = baseUrl + '/sso/login';
    const result = await fetch(ssoUrl, {
      credentials: 'include'
    });

    const data: SSOResponseData = (await result.json()) as SSOResponseData;

    // If already authenticated update the authCache and continue
    if (data.is_authenticated) {
      if (data.expires_at) {
        this.authCache.put(baseUrl, data.expires_at);
      }
    } else {
      // The returned data must contain an authn endpoint if the user is not authenticated
      if (!data.authn_endpoint) {
        throw Error('Response recieved without authn_endpoint for redirect.');
      }

      try {
        // Since authn_endpoint was included make a request to that endpoint
        const authResult = await fetch(data.authn_endpoint, {
          credentials: 'include'
        });

        //  If the response is not okay, it usually means the user isn't auth'd with Midway
        if (!authResult.ok) {
          throw Error('Non 2xx response received from auth endpoint.');
        }

        // Ok result should just contain a token.
        const token = await authResult.text();

        const loginResult = await fetch(`${ssoUrl}?id_token=${token}`, {
          credentials: 'include'
        });

        const loginData: SSOResponseData = (await loginResult.json()) as SSOResponseData;

        // Update authCache on success
        if (loginData.expires_at) {
          this.authCache.put(baseUrl, loginData.expires_at);
        } else {
          throw Error('Token for login failed.');
        }
      } catch (error) {
        // Probably need to flush this out but any error triggers a redirect to midway-auth (99% of the time this is the problem)
        const redirectUri = SentrySSOHandler.parseURL(this.curWindow.location.href);
        const queryParams = {
          client_id: redirectUri.baseUrl,
          redirect_uri: this.curWindow.location.href,
          response_type: 'id_token',
          scope: 'openid',
          nonce: SentrySSOHandler.generateNonce(),
          sentry_handler_version: 'MidwayNginxModule-1.6-1'
        };
        window.location.href = `https://midway-auth.amazon.com/login?next=/SSO/redirect%3F${encodeURIComponent(
          queryString.stringify(queryParams)
        )}`;
      }
    }
  }
}
