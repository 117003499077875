import React from 'react';
import { Route, Routes } from 'react-router';
import Redirect from '../../common/Redirect';
import {
  ALL_CHANGES_TAB_ID,
  ALL_CHANGES_TAB_ROUTE,
  RISK_TAB_ID,
  RISK_TAB_ROUTE
} from './table-definitions/review-tabs';
import Review from './Review';

const ReviewRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path='/:reviewId'
          element={<Redirect to={`/reviews/:reviewId/${RISK_TAB_ROUTE}`} replace={true} keepSearchParams={true} />}
        />
        <Route path={`/:reviewId/${RISK_TAB_ROUTE}`} element={<Review activeTabId={RISK_TAB_ID} />} />
        <Route path={`/:reviewId/${ALL_CHANGES_TAB_ROUTE}`} element={<Review activeTabId={ALL_CHANGES_TAB_ID} />} />
      </Routes>
    </>
  );
};

export default ReviewRoutes;
