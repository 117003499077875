import { createContext } from 'react';
import { RiskColumnDefinitionData } from '../table-definitions/common';

export interface CardRiskSelectionContextProps {
  riskSelectedItems: RiskColumnDefinitionData[];
  updateRiskSelectedItems: (riskSelection: RiskColumnDefinitionData[]) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const CardRiskSelectionContext = createContext<CardRiskSelectionContextProps>({
  riskSelectedItems: [],
  updateRiskSelectedItems: () => {}
});
