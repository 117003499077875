import React, { ReactElement } from 'react';
import i18n from '../../i18n';

/* eslint-disable @typescript-eslint/no-empty-function */
export interface SplitPanelContextProps {
  reset: () => void;
  // identifier used for being able to check what split panel contains (e.g. "diff-{reviewId}-{resourceId}")
  setContent: (content: ReactElement | undefined, identifier?: string) => void;
  getContentIdentifier: () => string;
  setHeader: (text: string) => void;
  setOpen: (isOpen: boolean) => void;
  isOpen: () => boolean;
}

export const SplitPanelContext = React.createContext<SplitPanelContextProps>({
  reset: () => {},
  setContent: () => {},
  getContentIdentifier: () => '',
  setHeader: () => {},
  setOpen: () => {},
  isOpen: () => false
});

export const SplitPanelI18nStrings = {
  preferencesTitle: i18n.t('Preferences'),
  preferencesPositionLabel: i18n.t('Split panel position'),
  preferencesPositionDescription: i18n.t('Choose the default split panel position for the service.'),
  preferencesPositionSide: i18n.t('Side'),
  preferencesPositionBottom: i18n.t('Bottom'),
  preferencesConfirm: i18n.t('Confirm'),
  preferencesCancel: i18n.t('Cancel'),
  closeButtonAriaLabel: i18n.t('Close'),
  openButtonAriaLabel: i18n.t('Open'),
  resizeHandleAriaLabel: i18n.t('Open'),
  sliderLabel: i18n.t('Slider')
};
