import i18n from '../../../i18n';
import { FilteringProperty } from '@awsui/collection-hooks/dist/cjs/interfaces';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { allFiltersSetToAll, allSelectOption, matchingRiskItem } from './filter-menu';
import {
  ResourceRevisionList,
  ResourceRevisionStructure
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import { generateExternalLink, generateRiskStatusDisplay } from '../../../common/common-components';
import { RiskColumnDefinitionData, resourceIdCell } from './common';
import { getSeverityEnum } from '../risks/Severity';

interface RiskTableProps {
  readonly tableItems: RiskColumnDefinitionData[];
  isLoadingRisks: boolean;
  isMutating: boolean;
  isFiltering: boolean;
  hasMoreRisks: boolean;
  totalRiskCount: number;
}

export const RISK_UNIQUE_IDENTIFIER_FIELD = 'riskId';

/**
 * Custom descending sorting for the Severity column.
 * @param left first item
 * @param right second item
 * @returns Negative value if right occurs before left, 0 if they are equal, positive otherwise.
 */
const severityComparator = (left: RiskColumnDefinitionData, right: RiskColumnDefinitionData) => {
  return getSeverityEnum(left.severity) - getSeverityEnum(right.severity);
};

const columnDefinitions = (reviewId: string, splitPanelContext: SplitPanelContextProps) => [
  {
    id: 'resource',
    header: i18n.t('Resource'),
    cell: (obj: RiskColumnDefinitionData) => {
      return resourceIdCell(obj, reviewId, splitPanelContext);
    },
    sortingField: 'resourceId'
  },
  {
    id: 'rule',
    header: i18n.t('Rule'),
    cell: (obj: RiskColumnDefinitionData) => generateExternalLink(`/rules/${obj.ruleId}`, obj.ruleName),
    sortingField: 'ruleName'
  },
  {
    id: 'severity',
    header: i18n.t('Severity'),
    cell: (obj: RiskColumnDefinitionData) => obj.severity,
    sortingField: 'severity',
    sortingComparator: severityComparator
  },
  {
    id: 'status',
    header: i18n.t('Status'),
    cell: (obj: RiskColumnDefinitionData) => generateRiskStatusDisplay(obj.status),
    sortingField: 'status'
  },
  {
    id: 'description',
    header: i18n.t('Description'),
    cell: (obj: RiskColumnDefinitionData) => obj.description,
    maxWidth: '20em',
    sortingField: 'description'
  },
  {
    id: 'createdOn',
    header: i18n.t('Date Created'),
    cell: (obj: RiskColumnDefinitionData) =>
      new Date(obj.createdOn).toLocaleString(undefined, { timeZoneName: 'short' }),
    sortingField: 'createdOn'
  }
];

export const RISK_FILTERING_PROPERTIES: FilteringProperty[] = [
  {
    propertyLabel: i18n.t('Severity'),
    key: 'severity',
    groupValuesLabel: i18n.t('Severity values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Rule'),
    key: 'ruleId',
    groupValuesLabel: i18n.t('Rule values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Status'),
    key: 'status',
    groupValuesLabel: i18n.t('Status values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Description'),
    key: 'description',
    groupValuesLabel: i18n.t('Description values'),
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: i18n.t('Date Created'),
    key: i18n.t('createdOn'),
    groupValuesLabel: i18n.t('Date Created values'),
    operators: [':', '!:', '=', '!=']
  }
];

/**
 * Custom sorting for the RiskTable items. This function is passed to the Polaris Table.
 * Sorts by severity descending and then createdOn date descending
 * @param left first item
 * @param right second item
 * @returns Negative value if right occurs before left, 0 if they are equal, positive otherwise.
 */
const riskTableSortingComparator = (left: RiskColumnDefinitionData, right: RiskColumnDefinitionData) => {
  return severityComparator(right, left) || right.createdOn?.localeCompare(left.createdOn);
};

const someRiskStatus = (items: readonly RiskColumnDefinitionData[], status: string) => {
  return items.some((item) => item.status === status);
};

const findResourceRevision = (
  item: RiskColumnDefinitionData,
  resourceRevisionsItems: ResourceRevisionList,
  resourceTypeFilter?: OptionDefinition
): ResourceRevisionStructure | undefined => {
  if (resourceTypeFilter?.value !== allSelectOption.value) {
    return resourceRevisionsItems.find((resourceRevision) => resourceRevision.resourceId === item.resourceId);
  }
  return undefined;
};

export const toFilteredRiskTableItems = (
  riskItems: RiskColumnDefinitionData[],
  resourceRevisionsItems: ResourceRevisionList,
  statusFilter: OptionDefinition,
  ruleFilter?: OptionDefinition,
  resourceTypeFilter?: OptionDefinition
) => {
  if (allFiltersSetToAll([statusFilter, ruleFilter, resourceTypeFilter])) {
    return riskItems;
  }
  const filteredItems = riskItems.filter((item) => {
    const resourceRevision = findResourceRevision(item, resourceRevisionsItems, resourceTypeFilter);
    return matchingRiskItem(statusFilter, item, ruleFilter, resourceTypeFilter, resourceRevision);
  });
  return filteredItems;
};

export { type RiskTableProps, columnDefinitions, riskTableSortingComparator, someRiskStatus };
