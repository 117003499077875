import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Box, Link } from '@amzn/awsui-components-react';

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <Box margin='l'>
      <Header>{t('Welcome to Change Guardian!')}</Header>
      <Box>
        {t("We're currently working on changes to our landing page and sidenav pages. For now, head back to ")}
        <Link external href='https://code.amazon.com'>
          code.amazon.com
        </Link>
        {t(" where you'll find links to specific Change Guardian reviews in open CRs.")}
      </Box>
    </Box>
  );
};

export default LandingPage;
