import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// By default picks up translations from /public/locales/{lng}/{ns}.json
// in dev but needs to be cross checked in production if path remains same.
// Refer: https://react.i18next.com/latest/i18next-instance

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(detector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    react: {
      // By default its true but we need to provide a fallback
      // render for our props leveraging translation in the future
      useSuspense: false
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
