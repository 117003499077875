import React, { useContext } from 'react';
import { JsonDiffFromPatch, JSONPatch } from '../json-diff';
import { Spinner } from '@amzn/awsui-components-react';
import useResourceRevisionDiffs from './react-query/useResourceRevisionDiffs';
import { useTranslation } from 'react-i18next';
import { ERROR_RETRY_MESSAGE, NotificationsContext } from '../../common/Notifications';
import { ErrorBoundary } from 'react-error-boundary';

interface ResourceRevisionDiffProps {
  readonly reviewId: string;
  readonly resourceId: string;
}

const ResourceRevisionDiff = (props: ResourceRevisionDiffProps) => {
  const { t } = useTranslation();
  const notificationsControls = useContext(NotificationsContext);

  const resourceRevisionDiffQuery = useResourceRevisionDiffs(props.reviewId, props.resourceId, (err) => {
    notificationsControls.addAWSErrorNotification(err);
  });

  if (resourceRevisionDiffQuery.isLoading || !resourceRevisionDiffQuery.data) {
    return <Spinner data-testid='resourceRevisionDiffSpinner' size='large' />;
  }

  if (resourceRevisionDiffQuery.isError) {
    return <></>;
  }

  const diffString = new TextDecoder('utf-8').decode(resourceRevisionDiffQuery.data.payload as Uint8Array);

  return (
    <ErrorBoundary
      fallback={<></>}
      onError={() =>
        notificationsControls.addNotification({
          content: `${t('Error parsing change details.')} ${ERROR_RETRY_MESSAGE}`,
          type: 'error'
        })
      }
    >
      <JsonDiffFromPatch patchObject={JSON.parse(diffString) as JSONPatch} />
    </ErrorBoundary>
  );
};

export default ResourceRevisionDiff;
