import { useQuery } from 'react-query';
import { useContext } from 'react';
import { ChangeGuardianClientContext, defaultQueryRetry } from '../../../client/ChangeGuardianClientProvider';
import { AWSError } from 'aws-sdk';

export default (reviewId: string, resourceId: string, onError?: (error: AWSError) => void) => {
  const client = useContext(ChangeGuardianClientContext);

  return useQuery(
    ['resourceRevisionDiff', reviewId, resourceId],
    ({ queryKey }) => {
      return client
        ?.getResourceRevisionDiffStream({
          reviewId: queryKey[1],
          resourceId: queryKey[2]
        })
        .promise();
    },
    {
      onError,
      retry: defaultQueryRetry
    }
  );
};
