import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { RiskColumnDefinitionData } from '../table-definitions/common';
import RiskFilterMenu from '../filter-menu/RiskFilterMenu';
import RiskCards from './RiskCards';
import RiskTable from './RiskTable';
import { riskByRuleDisplayOption } from '../table-definitions/filter-menu';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

interface RisksTabProps {
  riskItems: RiskColumnDefinitionData[];
  riskTableItems: RiskColumnDefinitionData[];
  riskDisplayOption: string;
  resourceRevisionItems: ResourceRevisionList;
  isLoadingRisks: boolean;
  hasMoreRisks: boolean;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean;
  isMutating: boolean;
  isFiltering: boolean;
}

const RisksTab = ({
  riskItems,
  riskTableItems,
  riskDisplayOption,
  resourceRevisionItems,
  isLoadingRisks,
  hasMoreRisks,
  isLoadingResourceRevisions,
  isMutating,
  isFiltering
}: RisksTabProps) => {
  return (
    <SpaceBetween size='s'>
      {riskItems.length ? (
        <RiskFilterMenu
          risksItems={riskItems}
          resourceRevisionsItems={resourceRevisionItems}
          isLoadingRisks={isLoadingRisks}
          isLoadingResourceRevisions={isLoadingResourceRevisions}
        />
      ) : null}
      {riskDisplayOption === riskByRuleDisplayOption.id ? (
        <RiskCards
          riskItems={riskTableItems}
          isLoadingRisks={isLoadingRisks}
          hasMoreRisks={hasMoreRisks}
          isMutating={isMutating}
          totalRiskCount={riskItems.length}
        />
      ) : (
        <RiskTable
          tableItems={riskTableItems}
          isLoadingRisks={isLoadingRisks}
          isMutating={isMutating}
          isFiltering={isFiltering}
          hasMoreRisks={hasMoreRisks}
          totalRiskCount={riskItems.length}
        />
      )}
    </SpaceBetween>
  );
};

export default RisksTab;
