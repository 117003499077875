import { createContext } from 'react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { allSelectOption } from '../table-definitions/filter-menu';

export interface ResourceRevisionsContextProps {
  filterResourceRevisions: (operationValue: OptionDefinition, resourceTypeValue: OptionDefinition) => void;
  filterOperation: (option: OptionDefinition) => void;
  filterResourceType: (option: OptionDefinition) => void;
  resetAllResourceRevisionFilters: () => void;
  operationFilterValue: OptionDefinition;
  resourceTypeFilterValue: OptionDefinition;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const ResourceRevisionsContext = createContext<ResourceRevisionsContextProps>({
  filterResourceRevisions: () => {},
  filterOperation: () => {},
  filterResourceType: () => {},
  resetAllResourceRevisionFilters: () => {},
  operationFilterValue: allSelectOption,
  resourceTypeFilterValue: allSelectOption
});
