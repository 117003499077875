import React from 'react';
import { applyPatch, Operation } from 'fast-json-patch';

import { Box } from '@amzn/awsui-components-react';

import { diffJson, Change } from 'diff';

interface DiffProps {
  readonly oldObject: object;
  readonly newObject: object;
}

export interface JSONPatch {
  currentState: object;
  patch: Array<Operation>;
}

interface DiffFromPatchProps {
  readonly patchObject: JSONPatch;
}

const getBoxColor = (change: Change) => {
  if (change.added) {
    return 'text-status-success';
  }

  if (change.removed) {
    return 'text-status-error';
  }

  return 'inherit';
};

const getTestId = (change: Change) => {
  if (change.added) {
    return 'diff-addition';
  }

  if (change.removed) {
    return 'diff-removal';
  }

  return 'diff-same';
};

export const JsonDiff = (props: DiffProps) => {
  const changes = diffJson(props.oldObject, props.newObject);

  return (
    <Box margin='m'>
      {changes.map((change, index) => {
        return (
          <Box key={index} margin='n' variant='pre' color={getBoxColor(change)} data-testid={getTestId(change)}>
            {change.value}
          </Box>
        );
      })}
    </Box>
  );
};

export const JsonDiffFromPatch = (props: DiffFromPatchProps) => {
  const oldObject = props.patchObject.currentState;
  const newObject = applyPatch(props.patchObject, props.patchObject.patch, true, false).newDocument.currentState;

  return <JsonDiff oldObject={oldObject} newObject={newObject} />;
};
