import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';

interface RedirectProps {
  to: string;
  replace: boolean;
  keepSearchParams: boolean;
}

/*
  Wrapper for react-router Navigate component: https://reactrouter.com/en/main/components/navigate
*/
const Redirect = ({ to, replace, keepSearchParams }: RedirectProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const buildURL = () => {
    const paramEntries = Object.entries(params);
    let path = to;
    // Carry over the value of the dynamic segments, e.g /:reviewId
    paramEntries.forEach(([key, value]) => {
      if (value) {
        path = path.replace(`:${key}`, `${value}`);
      }
    });
    if (keepSearchParams) {
      // Carry over the value of the search parameters, e.g ?riskStatus=acknowledged
      path = `${path}?${searchParams.toString()}`;
    }
    return path;
  };

  return <Navigate to={buildURL()} replace={replace} />;
};

export default Redirect;
