import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import RisksTab from './risks/RiskTab';
import ResourceRevisionsTab from './resource-revisions/ResourceRevisionTab';
import { RiskColumnDefinitionData } from './table-definitions/common';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import {
  ALL_CHANGES_TAB_ID,
  ALL_CHANGES_TAB_LABEL,
  RISK_TAB_ID,
  RISK_TAB_LABEL,
  reviewTabsIdToRouteMap
} from './table-definitions/review-tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ReviewTabsProps {
  activeTabId: string;
  riskItems: RiskColumnDefinitionData[];
  riskTableItems: RiskColumnDefinitionData[];
  riskDisplayOption: string;
  resourceRevisionItems: ResourceRevisionList;
  resourceRevisionTableItems: ResourceRevisionList;
  isLoadingRisks: boolean;
  hasMoreRisks: boolean;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean;
  isMutating: boolean;
  isFiltering: boolean;
}

const ReviewTabs = ({
  activeTabId,
  riskItems,
  riskTableItems,
  riskDisplayOption,
  resourceRevisionItems,
  resourceRevisionTableItems,
  isLoadingRisks,
  hasMoreRisks,
  isLoadingResourceRevisions,
  hasMoreResourceRevisions,
  isMutating,
  isFiltering
}: ReviewTabsProps) => {
  const [activeTab, setActiveTab] = useState(activeTabId);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [riskSearchParams, setRisksSearchParams] = useState(activeTabId === RISK_TAB_ID ? searchParams.toString() : '');
  const [allChangesSearchParams, setAllChangesSearchParams] = useState(
    activeTabId === ALL_CHANGES_TAB_ID ? searchParams.toString() : ''
  );

  const onChangeHandler = (newActiveTab: string) => {
    let tabPath = `${reviewTabsIdToRouteMap[newActiveTab]}`;
    if (newActiveTab === RISK_TAB_ID && riskSearchParams) {
      tabPath = `${tabPath}?${riskSearchParams}`;
    }
    if (newActiveTab === ALL_CHANGES_TAB_ID && allChangesSearchParams) {
      tabPath = `${tabPath}?${allChangesSearchParams}`;
    }
    navigate(`../${tabPath}`, { relative: 'path' });
  };

  useEffect(() => {
    if (activeTabId === RISK_TAB_ID) {
      setRisksSearchParams(searchParams.toString());
    }
    if (activeTabId === ALL_CHANGES_TAB_ID) {
      setAllChangesSearchParams(searchParams.toString());
    }
  }, [searchParams, activeTabId]);

  useEffect(() => {
    setActiveTab(activeTabId);
  }, [activeTabId]);

  return (
    <Tabs
      onChange={({ detail }: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => onChangeHandler(detail.activeTabId)}
      activeTabId={activeTab}
      tabs={[
        {
          label: RISK_TAB_LABEL,
          id: RISK_TAB_ID,
          content: (
            <RisksTab
              riskItems={riskItems}
              riskTableItems={riskTableItems}
              riskDisplayOption={riskDisplayOption}
              resourceRevisionItems={resourceRevisionItems}
              isLoadingRisks={isLoadingRisks}
              hasMoreRisks={hasMoreRisks}
              isLoadingResourceRevisions={isLoadingResourceRevisions}
              hasMoreResourceRevisions={hasMoreResourceRevisions}
              isMutating={isMutating}
              isFiltering={isFiltering}
            />
          )
        },
        {
          label: ALL_CHANGES_TAB_LABEL,
          id: ALL_CHANGES_TAB_ID,
          content: (
            <>
              <ResourceRevisionsTab
                resourceRevisionItems={resourceRevisionItems}
                resourceRevisionTableItems={resourceRevisionTableItems}
                isLoadingResourceRevisions={isLoadingResourceRevisions}
                hasMoreResourceRevisions={hasMoreResourceRevisions}
              />
            </>
          )
        }
      ]}
    />
  );
};

export default ReviewTabs;
