import { FlashbarProps } from '@amzn/awsui-components-react';
import { AWSError } from 'aws-sdk';
import { t } from 'i18next';
import { createContext, Reducer } from 'react';

/**
 * This context is used globally for notifications
 * Notifications are injected to the AppLayout
 * in the layout housing component.
 */

export interface NotificationContextProps {
  reset: () => void;
  addNotification: (notification: FlashbarProps.MessageDefinition) => void;
  addAWSErrorNotification: (error: AWSError, customErrorMessage?: string) => void;
  addSuccessNotification: (notificationContent: string) => void;
  addBatchErrorNotification: (errors: Map<string, string[]>, customErrorMessage: string) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const NotificationsContext = createContext<NotificationContextProps>({
  reset: () => {},
  addNotification: () => {},
  addAWSErrorNotification: () => {},
  addSuccessNotification: () => {},
  addBatchErrorNotification: () => {}
});

type NotificationActions =
  | {
      type: 'insert';
      notification: FlashbarProps.MessageDefinition;
    }
  | {
      id: string;
      type: 'remove';
    }
  | {
      type: 'reset';
    };

export const notificationsReducer: Reducer<ReadonlyArray<FlashbarProps.MessageDefinition>, NotificationActions> = (
  notifications,
  action: NotificationActions
) => {
  switch (action.type) {
    case 'insert': {
      const notificationsCopy = [...notifications];
      notificationsCopy.push(action.notification);
      return notificationsCopy;
    }
    case 'reset': {
      return [];
    }
    case 'remove': {
      const { id } = action;
      const index = notifications.findIndex((item) => item.id === id);

      const notificationsCopy = [...notifications];
      notificationsCopy.splice(index, 1);

      return notificationsCopy;
    }
  }
};

export const ERROR_RETRY_MESSAGE = t('If the error persists after retrying, please get in touch with the team.');

export const awsErrorToErrorNotificationMessage = (error: AWSError) => {
  switch (error?.statusCode) {
    case 409:
    case 400: {
      return error.message;
    }
    case 404: {
      return t('Resource does not exist.');
    }
    case 403: {
      return t('User is not authorized to perform this action.');
    }
    case 429: {
      return t('Your request is being throttled. Retry later.');
    }
    case 500: {
      return `${t('An internal failure occurred.')} ${ERROR_RETRY_MESSAGE}`;
    }
    default: {
      return `${t('An unknown error occurred.')} ${ERROR_RETRY_MESSAGE}`;
    }
  }
};
