import { Box, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
  const { t } = useTranslation();

  return (
    <Box margin='l'>
      <Header>{t('404 Page Not Found')}</Header>
      <Box>{t("The page you're looking for does not exist.")}</Box>
    </Box>
  );
}
