import { generateRiskStatusDisplay } from '../../../common/common-components';
import i18n from '../../../i18n';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import { RiskColumnDefinitionData, resourceIdCell } from './common';

export const columnDefinitions = (reviewId: string, splitPanelContext: SplitPanelContextProps) => [
  {
    id: 'resource',
    header: i18n.t('Resource'),
    cell: (obj: RiskColumnDefinitionData) => {
      return resourceIdCell(obj, reviewId, splitPanelContext);
    },
    sortingField: 'id'
  },
  {
    id: 'status',
    header: i18n.t('Status'),
    cell: (obj: RiskColumnDefinitionData) => generateRiskStatusDisplay(obj.status),
    sortingField: 'status'
  },
  {
    id: 'createdOn',
    header: i18n.t('Date Created'),
    cell: (obj: RiskColumnDefinitionData) =>
      new Date(obj.createdOn).toLocaleString(undefined, { timeZoneName: 'short' }),
    sortingField: 'createdOn'
  }
];

export interface RiskCardTableProps {
  readonly riskItems: RiskColumnDefinitionData[];
  ruleId: string;
  isLoading: boolean;
}
