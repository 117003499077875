import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { ChangeGuardianClientContext, getClient } from './client/ChangeGuardianClientProvider';
import { SettingsContext } from './common/SetttingsProvider';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import Router from './Router';

const App = () => {
  const queryClient = new QueryClient();
  const { stage } = useContext(SettingsContext);

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    applyMode(Mode.Dark);
  } else {
    applyMode(Mode.Light);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChangeGuardianClientContext.Provider value={getClient(stage)}>
        <RouterProvider router={Router} />
      </ChangeGuardianClientContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
