enum Severity {
  UNKNOWN,
  COSMETIC,
  LOW,
  MEDIUM,
  HIGH,
  CRITICAL
}

/**
 * @param severity Severity value as a string literal from the approval service
 * @returns Returns the corresponding severity enum, defaults to Severity.UNKNOWN
 */
const getSeverityEnum = (severity: string) => {
  const severityKey = severity.toUpperCase() as keyof typeof Severity;
  return Severity[severityKey] || Severity.UNKNOWN;
};

export { Severity, getSeverityEnum };
