import ChangeGuardianApprovalService, {
  BatchUpdateRiskStatusOutput,
  BatchUpdateRiskStatusInput,
  UpdateRiskStatusList,
  UpdateRiskStatusFailureEntry
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { AWSError } from 'aws-sdk';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ChangeGuardianClientContext, defaultQueryRetry } from '../../../client/ChangeGuardianClientProvider';
import { Promise } from 'bluebird';
import { awsErrorToErrorNotificationMessage } from '../../../common/Notifications';

const API_MAX_BATCH_SIZE = 100;
const BATCH_CONCURRENCY = 8;

const splitRiskList = (args: BatchUpdateRiskStatusInput): BatchUpdateRiskStatusInput[] => {
  const riskArrayChunks = new Array<UpdateRiskStatusList>();
  for (let i = 0; i < args.updateRiskStatusList.length; i += API_MAX_BATCH_SIZE) {
    riskArrayChunks.push(args.updateRiskStatusList.slice(i, i + API_MAX_BATCH_SIZE));
  }
  return riskArrayChunks.map((chunk) => {
    return {
      reviewId: args.reviewId,
      updateRiskStatusList: chunk
    };
  });
};

const batchUpdateRisksStatus = async (
  client: ChangeGuardianApprovalService | undefined,
  riskBatch: BatchUpdateRiskStatusInput
): Promise<BatchUpdateRiskStatusOutput | undefined> => {
  try {
    return await client?.batchUpdateRiskStatus(riskBatch).promise();
  } catch (err) {
    const errorMessage = awsErrorToErrorNotificationMessage(err as AWSError);
    const updateErrors = riskBatch.updateRiskStatusList.map((risk) => {
      return {
        resourceId: risk.resourceId,
        ruleId: risk.ruleId,
        dedupeToken: risk.dedupeToken,
        status: risk.status,
        errorMessage
      } as UpdateRiskStatusFailureEntry;
    });
    return { successes: [], failures: updateErrors } as BatchUpdateRiskStatusOutput;
  }
};

export default (
  onSuccess?: (data: BatchUpdateRiskStatusOutput, variables: BatchUpdateRiskStatusInput) => void,
  onError?: (error: AWSError) => void
) => {
  const client = useContext(ChangeGuardianClientContext);
  return useMutation<BatchUpdateRiskStatusOutput, AWSError, BatchUpdateRiskStatusInput>(
    async (args) => {
      const updateRiskStatusBatches = splitRiskList(args);
      const batchUpdateResult = await Promise.map(
        updateRiskStatusBatches,
        (batch) => {
          return batchUpdateRisksStatus(client, batch);
        },
        { concurrency: BATCH_CONCURRENCY }
      );
      return {
        successes: batchUpdateResult
          .map((batch) => batch?.successes)
          .flat()
          .filter((result) => !!result),
        failures: batchUpdateResult
          .map((batch) => batch?.failures)
          .flat()
          .filter((result) => !!result)
      } as BatchUpdateRiskStatusOutput;
    },
    {
      onSuccess,
      onError,
      retry: defaultQueryRetry
    }
  );
};
