import { SpaceBetween } from '@amzn/awsui-components-react';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import React from 'react';
import ResourceRevisionTable from './ResourceRevisionTable';
import ResourceRevisionsFilterMenu from '../filter-menu/ResourceRevisionFilterMenu';

interface ResourceRevisionsTabProps {
  resourceRevisionItems: ResourceRevisionList;
  resourceRevisionTableItems: ResourceRevisionList;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean | undefined;
}

const ResourceRevisionsTab = ({
  resourceRevisionItems,
  resourceRevisionTableItems,
  isLoadingResourceRevisions,
  hasMoreResourceRevisions
}: ResourceRevisionsTabProps) => {
  return (
    <SpaceBetween size='s'>
      {resourceRevisionItems.length ? (
        <ResourceRevisionsFilterMenu
          resourceRevisionsItems={resourceRevisionItems}
          isLoadingResourceRevisions={isLoadingResourceRevisions}
        />
      ) : null}
      <ResourceRevisionTable
        tableItems={resourceRevisionTableItems}
        totalResourceRevisionsCount={resourceRevisionItems.length}
        isLoadingResourceRevisions={isLoadingResourceRevisions}
        hasMoreResourceRevisions={hasMoreResourceRevisions || false}
        isFiltering={false}
      />
    </SpaceBetween>
  );
};

export default ResourceRevisionsTab;
