import React from 'react';
import { Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

interface DynamicSelectProps {
  isLoading: boolean;
  setFilter: (value: OptionDefinition) => void;
  testId: string;
  options: OptionDefinition[];
  value: OptionDefinition;
  ariaLabel: string;
}

const DynamicSelect = ({ isLoading, setFilter, testId, options, value, ariaLabel }: DynamicSelectProps) => {
  return (
    <Select
      selectedOption={isLoading ? null : value}
      options={options}
      onChange={({ detail }) => {
        setFilter(detail.selectedOption);
      }}
      disabled={isLoading}
      selectedAriaLabel={ariaLabel}
      data-testid={testId}
      placeholder='Loading options...'
    />
  );
};

export default DynamicSelect;
