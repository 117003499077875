import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { ResourceRevisionStructure } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

enum ResourceRevisionOperation {
  ADD,
  REMOVE,
  MODIFY,
  UNAVAILABLE
}

const getResourceOperationDisplay = (rev: ResourceRevisionStructure) => {
  switch (rev.operation) {
    case ResourceRevisionOperation[ResourceRevisionOperation.ADD]:
      return <Box color='text-status-info'>{ResourceRevisionOperation[ResourceRevisionOperation.ADD]}</Box>;
    case ResourceRevisionOperation[ResourceRevisionOperation.REMOVE]:
      return <Box color='text-status-error'>{ResourceRevisionOperation[ResourceRevisionOperation.REMOVE]}</Box>;
    case ResourceRevisionOperation[ResourceRevisionOperation.MODIFY]:
      return <Box color='text-status-warning'>{ResourceRevisionOperation[ResourceRevisionOperation.MODIFY]}</Box>;
    default:
      return <Box>{ResourceRevisionOperation[ResourceRevisionOperation.UNAVAILABLE]}</Box>;
  }
};

export { ResourceRevisionOperation, getResourceOperationDisplay };
