import { useQuery } from 'react-query';
import { ChangeGuardianClientContext, defaultQueryRetry } from '../../../client/ChangeGuardianClientProvider';
import { useContext } from 'react';
import { AWSError } from 'aws-sdk';

export default (ruleId: string, onError?: (error: AWSError) => void) => {
  const client = useContext(ChangeGuardianClientContext);

  return useQuery(
    ['ruleDetails', ruleId],
    ({ queryKey }) => {
      return client
        ?.getRule({
          ruleId: queryKey[1]
        })
        .promise();
    },
    {
      onError,
      retry: defaultQueryRetry
    }
  );
};
