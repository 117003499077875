import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { SettingsContext, fetchSettings, defaultSettings } from './common/SetttingsProvider';

import '@amzn/awsui-global-styles/polaris.css';

document.addEventListener('DOMContentLoaded', () => {
  fetchSettings() // Initialize the page by fetching the settings first
    .then((runtimeSettings) => {
      ReactDOM.render(
        <SettingsContext.Provider value={runtimeSettings}>
          <App />
        </SettingsContext.Provider>,
        document.querySelector('#app')
      );
    })
    .catch((err) => {
      console.log('Settings.json was not read, falling back to dev stage.', err);
      ReactDOM.render(
        <SettingsContext.Provider value={defaultSettings}>
          <App />
        </SettingsContext.Provider>,
        document.querySelector('#app')
      );
    });
});
